// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Button } from '@plesk/ui-library';
import { Locale } from 'jsw';
import Link from 'components/common/Link';
import { useQuery } from '@apollo/client';

import HOME_PAGE from 'queries/AdminHomePage.graphql';

const Translate = Locale.getTranslate('components.buttons');

const TitleAddon = () => {
    const response = useQuery(HOME_PAGE);

    return !response.data?.viewer?.home?.locked && (
        <Button
            ghost
            id="customize"
            component={Link}
            to="/cp/home/customize"
            icon={{
                name: 'gear',
                style: {
                    order: '1',
                    marginInline: '4px 0',
                },
            }}
        >
            <Translate content="customize" />
        </Button>
    );
};

export default TitleAddon;
