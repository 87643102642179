// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as constants from '../constants';
import SERVER_INFO from 'queries/AdminHomeServerInfo.graphql';
import UPDATES from 'queries/AdminHomeUpdates.graphql';
import OVERUSED_SUBSCRIPTIONS from 'queries/AdminHomeOverusedSubscriptions.graphql';
import MY_SUBSCRIPTIONS_QUERY from 'queries/AdminHomeMySubscriptions.graphql';
import EXTENSIONS_QUERY from 'queries/AdminHomeExtensions.graphql';

export default {
    [constants.SERVER_INFO]: SERVER_INFO,
    [constants.UPDATES]: UPDATES,
    [constants.OVERUSED_SUBSCRIPTIONS]: OVERUSED_SUBSCRIPTIONS,
    [constants.MY_SUBSCRIPTIONS]: MY_SUBSCRIPTIONS_QUERY,
    [constants.EXTENSIONS]: EXTENSIONS_QUERY,
};
